import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorI18n } from '@ridango/i18n';

@Injectable()
export class CustomPaginatorIntl extends PaginatorI18n {
    constructor(translate: TranslateService) {
        super(translate);
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === Number.POSITIVE_INFINITY) {
            const lowerBound = page * pageSize + 1;
            const upperBound = (page + 1) * pageSize;
            return `${lowerBound} – ${upperBound}`;
        }

        if (length == 0 || pageSize == 0) {
            return this.translate.instant('portal.pagination.range_page_label_1', {
                length: length
            });
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return this.translate.instant('portal.pagination.range_page_label_2', {
            startIndex: startIndex,
            endIndex: endIndex,
            length: length
        });
    };
}
