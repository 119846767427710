import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@ridango/auth';
import { NavigationService } from '@ridango/ui/fuse-core/navigation/navigation.service';
import { ToolbarDropdownItem } from '@ridango/ui/fuse-core/navigation/navigation.types';
import { combineLatest } from 'rxjs';
import { ERoles } from './core/authorization/roles.enum';
import { ChangeRolesComponent } from './core/change-roles/change-roles/change-roles.component';
import { NavigationItemService } from './navigation-item.service';
import { ModalService } from './widgets/modal/modal.service';

@Injectable({ providedIn: 'root' })
export class NavigationGenerator {
    private clientId: string;

    constructor(
        private modalService: ModalService,
        private authenticationService: AuthService,
        private navigationService: NavigationService,
        private translateService: TranslateService,
        private navigationItemService: NavigationItemService
    ) {
    }

    generate() {
        combineLatest([this.authenticationService.session$, this.translateService.onLangChange]).subscribe(
            ([session]) => {
                this.clientId = session.resource.activeRole.role.clientId;
                this.composeToolbarDropdown();
                this.composeMenu();
            }
        );
    }

    private composeToolbarDropdown() {
        const items: ToolbarDropdownItem[] = [
            {
                title: this.translateService.instant('common.changeRole'),
                icon: 'heroicons_outline:user',
                onClick: () => this.modalService.open(ChangeRolesComponent),
                type: 'button'
            },
            {
                type: 'divider'
            },
            {
                title: this.translateService.instant('common.logout'),
                icon: 'heroicons_outline:logout',
                onClick: () => this.authenticationService.logout(),
                type: 'button'
            }
        ];
        this.navigationService.setToolbarDropdownItems(items);
    }

    private navigationConfigByRole(): FuseNavigationItem[] {
        const currentRole = Object.values(ERoles).find((config) => this.clientId.endsWith(config)) as ERoles;
        if (!currentRole) {
            return [];
        }
        const options = this.navigationItemService.items;
        switch (currentRole) {
            case ERoles.CompanyAdmin:
                return [
                    options.dashboard,
                    options.validators,
                    options.pos,
                    options.cards,
                    options.transactions,
                    options.currentOrganization
                ];
            case ERoles.Superuser:
                return [
                    options.dashboard,
                    options.cards,
                    options.settings,
                    options.reports,
                    options.acquirers,
                    options.merchants,
                    options.organizations,
                    options.audit,
                    options.about
                ];
            case ERoles.DeviceManager:
                return [options.dashboard, options.validators, options.pos];
            case ERoles.Technician:
                return [options.dashboard, options.validators];
            case ERoles.PaymentsSupportPan:
                return [options.dashboard, options.cards, options.transactions];
            case ERoles.PaymentsSupport:
                return [options.dashboard, options.cards, options.transactions];
        }
    }

    private composeMenu(): void {
        const main: FuseNavigationItem[] = [
            {
                title: this.translateService.instant('common.appName'),
                subtitle: this.translateService.instant('common.appDescription'),
                type: 'group',
                children: [{ type: 'divider' }, ...this.navigationConfigByRole()]
            }
        ];
        const navigation = {
            default: main,
            compact: [],
            futuristic: [],
            horizontal: []
        };

        this.navigationService.setItems(navigation);
    }
}
