import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslateService } from '@ngx-translate/core';
import { EAppRoutes } from './navigation.models';

@Injectable({
    providedIn: 'root'
})
export class NavigationItemService {
    constructor(private translateService: TranslateService) {
    }

    get items(): Record<string, FuseNavigationItem> {
        return {
            dashboard: {
                type: 'basic',
                title: this.translateService.instant('common.menu.dashboard'),
                icon: 'heroicons_outline:view-grid',
                link: `/${EAppRoutes.Dashboard}`
            },
            validators: {
                type: 'basic',
                title: this.translateService.instant('common.menu.validators'),
                icon: 'ridango:validators',
                link: `/${EAppRoutes.Validators}`
            },
            pos: {
                type: 'basic',
                title: this.translateService.instant('common.menu.pos'),
                icon: 'ridango:sales-point',
                link: `/${EAppRoutes.Pos}`
            },
            cards: {
                type: 'collapsable',
                title: this.translateService.instant('common.menu.cards'),
                icon: 'heroicons_outline:credit-card',
                children: [
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.activeCards'),
                        link: `/${EAppRoutes.Cards}/${EAppRoutes.Active}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.debtCards'),
                        link: `/${EAppRoutes.Cards}/${EAppRoutes.Debt}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.allCards'),
                        link: `/${EAppRoutes.Cards}/${EAppRoutes.All}`
                    }
                ]
            },
            transactions: {
                type: 'basic',
                title: this.translateService.instant('common.menu.transactions'),
                icon: 'heroicons_outline:currency-euro',
                link: `/${EAppRoutes.Transactions}`
            },
            organizations: {
                type: 'basic',
                title: this.translateService.instant('common.menu.company'),
                icon: 'heroicons_outline:office-building',
                link: `/${EAppRoutes.Organizations}`
            },
            currentOrganization: {
                type: 'basic',
                title: this.translateService.instant('common.menu.companyDetails'),
                icon: 'heroicons_outline:office-building',
                link: `/${EAppRoutes.Organizations}/${EAppRoutes.CurrentOrganization}`
            },
            audit: {
                type: 'basic',
                title: this.translateService.instant('common.menu.audit'),
                icon: 'heroicons_outline:clipboard-check',
                link: `/${EAppRoutes.Audit}`
            },
            about: {
                type: 'basic',
                title: this.translateService.instant('common.menu.about'),
                icon: 'heroicons_outline:information-circle',
                link: `/${EAppRoutes.About}`
            },
            acquirers: {
                type: 'basic',
                title: this.translateService.instant('common.menu.acquirers'),
                icon: 'heroicons_outline:library',
                link: `/${EAppRoutes.Acquirers}`
            },
            merchants: {
                type: 'basic',
                title: this.translateService.instant('common.menu.merchants'),
                icon: 'heroicons_outline:shopping-bag',
                link: `/${EAppRoutes.Merchants}`
            },
            reports: {
                type: 'collapsable',
                title: this.translateService.instant('common.menu.reports'),
                icon: 'heroicons_outline:document-text',
                children: [
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.settlementReports'),
                        link: `/${EAppRoutes.Reports}/${EAppRoutes.SettlementReports}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.merchantPaymentReports'),
                        link: `/${EAppRoutes.Reports}/${EAppRoutes.MerchantPaymentReports}`
                    }
                ]
            },
            settings: {
                type: 'collapsable',
                title: this.translateService.instant('common.menu.settings'),
                icon: 'heroicons_outline:cog',
                children: [
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.properties'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.Properties}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.templates'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.Templates}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.schemas'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.Schemas}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.currencies'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.Currencies}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.jobs'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.Jobs}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.liabilityRegions'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.LiabilityRegions}`
                    },
                    {
                        type: 'basic',
                        title: this.translateService.instant('common.menu.regions'),
                        link: `/${EAppRoutes.Settings}/${EAppRoutes.Regions}`
                    }
                ]
            }
        };
    }
}
