import { Injectable, Optional } from '@angular/core';
import { TranslateCompiler, TranslateLoader, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

import enGB from './translations/en-GB.json';
import et from './translations/et.json';
import ru from './translations/ru.json';
import lt from './translations/lt.json';
import fi from './translations/fi.json';
import sv from './translations/sv.json';
import de from './translations/de.json';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export type LANGUAGE = 'en-GB' | 'et' | 'ru' | 'lt' | 'fi' | 'sv' | 'de';

export const compiler = {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler,
};

export const httpLoader = {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => {
        return new TranslateHttpLoader(http);
    },
    deps: [HttpClient],
};

@Injectable()
export class I18nService {
    public enabledLanguages: LANGUAGE[] = ['en-GB'];

    private languages: { id: LANGUAGE; label: string }[] = [
        { id: 'en-GB', label: 'English' },
        { id: 'et', label: 'Eesti' },
        { id: 'lt', label: 'Lietuviškai' },
        { id: 'ru', label: 'Русский' },
        { id: 'fi', label: 'Suomi' },
        { id: 'sv', label: 'Svenska' },
        { id: 'de', label: 'Deutsch' },
    ];

    private translations = {
        'en-GB': enGB,
        et,
        ru,
        lt,
        fi,
        sv,
        de,
    };

    constructor(@Optional() private translateService: TranslateService) {
        this._activeLang = (localStorage.getItem('currentLang') as LANGUAGE) ?? this._activeLang;

        this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
            this.translateService.setTranslation(event.lang, this.translations[event.lang as LANGUAGE], true);
        });

        this.translateService?.setDefaultLang('en-GB');
        this.translateService?.use(this.activeLang);
    }

    private _activeLang: LANGUAGE = 'en-GB';

    public set activeLang(lang: LANGUAGE) {
        this._activeLang = lang;
        this.translateService?.use(lang);
        localStorage.setItem('currentLang', lang);
        window.location.reload();
    }

    public get activeLang() {
        return this._activeLang;
    }

    public get availableLanguages() {
        return this.languages.filter((lang) => this.enabledLanguages.includes(lang.id));
    }
}
