import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { StorageFactory } from '@payments/list-manager';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { Role } from '../../../services/roles/roles.models';
import { RolesService } from '../../../services/roles/roles.service';
import { ModalService } from '../../../widgets/modal/modal.service';
import { AuthorizationService } from '../../authorization/authorization.service';

interface RolesGroup {
    companyName: string;
    roles: Role[];
}

@Component({
    selector: 'payments-change-roles',
    templateUrl: './change-roles.component.html',
    styleUrls: ['./change-roles.component.scss']
})
export class ChangeRolesComponent {
    rolesControl = new UntypedFormControl();
    rolesGroups: RolesGroup[];
    filteredRolesGroups$: Observable<RolesGroup[]>;
    activeRole: undefined | Role;
    loading = false;

    constructor(
        private modalService: ModalService,
        private authorizationService: AuthorizationService,
        private rolesService: RolesService
    ) {
        // group together all roles that have the same company name

        combineLatest([this.authorizationService.activeRole$, this.authorizationService.availableRoles$])
            .pipe(take(1))
            .subscribe(([activeRole, availableRoles]) => {
                this.rolesControl.setValue(activeRole);

                this.rolesGroups = availableRoles.reduce(function(grouped: RolesGroup[], role: Role) {
                    const group = grouped.find((r) => r && r.companyName === role.companyName);
                    if (group) {
                        group.roles.push(role);
                    } else {
                        grouped.push({ companyName: role.companyName, roles: [role] });
                    }
                    return grouped;
                }, []);
            });

        this.filteredRolesGroups$ = this.rolesControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.filterGroups(value))
        );
    }

    submit() {
        this.loading = true;
        const role = this.rolesControl.value;
        this.rolesService.selectRole(role.id).pipe(
            switchMap(() => this.authorizationService.refreshSession())
        ).subscribe(() => {
            StorageFactory.sessionStorage.clear();
            StorageFactory.urlStorage.clear();
            window.location.href = '/';
        });
    }

    close(): void {
        this.modalService.closeAll();
    }

    displayRole(role: Role): string {
        return role ? `${role.companyName}: ${role.roleName}` : '';
    }

    clear() {
        this.rolesControl.reset();
    }

    private filterGroups(value: string | Role): RolesGroup[] {
        // if value is present, return all role groups which have at least one role that matches given value
        // otherwise, return all groups and roles
        if (value) {
            return this.rolesGroups
                .map((group) => ({ companyName: group.companyName, roles: this.filterRoles(group.roles, value) }))
                .filter((group) => group.roles.length > 0);
        }
        return this.rolesGroups;
    }

    private filterRoles(roles: Role[], value: string | Role): Role[] {
        // if value is a string, then find all roles for a specific group that match given value
        // if value is a role, then find only the matching role
        if (typeof value === 'string') {
            const filterValue = this.removeDiacritics(value.toLowerCase());
            return roles.filter((role) => {
                return (
                    this.removeDiacritics(`${role.companyName}: ${role.roleName}`.toLowerCase()).indexOf(
                        filterValue
                    ) !== -1
                );
            });
        } else {
            return roles.filter((role) => role.id === value.id);
        }
    }

    private removeDiacritics(str: string) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
