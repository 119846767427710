import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, forkJoin, map, of } from 'rxjs';

class PaymentsManagerTranslateLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    public get folderNames(): string[] {
        return ['admin-response-codes', 'nets-action-codes', 'notifications', 'gateway-codes'];
    }

    public getTranslation(lang: string) {
        const main = this.http.get('assets/i18n/' + lang + '.json');
        const subfolders = this.folderNames.map((folderName) =>
            this.http.get('assets/i18n/' + folderName + '/' + lang + '.json').pipe(catchError(() => of(null)))
        );

        return forkJoin([main, ...subfolders]).pipe(
            map((translations) =>
                translations
                    .filter((translation) => !!translation)
                    .reduce((prev, current) => ({ ...prev, ...current }), {})
            )
        );
    }
}

export default {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => {
        return new PaymentsManagerTranslateLoader(http);
    },
    deps: [HttpClient],
};
